/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Main from './components/Main';

export default function App() {
  return (
    <>
      {/* <Header /> */}
      <Main />
      <Footer />
    </>
  );
}
